/* eslint-disable react/prop-types */
/* eslint-disable import/no-unused-modules */
import { Trans } from '@lingui/macro'
import { BigintIsh, CurrencyAmount, Fraction, Token } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import Earnings from 'components/Earnings'
import { ArrowChangeDown } from 'components/Icons/ArrowChangeDown'
import { ArrowChangeUp } from 'components/Icons/ArrowChangeUp'
import { Info } from 'components/Icons/Info'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
  SMALL_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import { LoadingBubble } from 'components/Tokens/loading'
import { MouseoverTooltip } from 'components/Tooltip'
import { DAYS_IN_YEAR, SITE_NAME } from 'constants/misc'
import { DEFAULT_ERC20_DECIMALS } from 'constants/tokens'
import { getFarmV3DetailsURL, validateUrlChainParam } from 'graphql/data/util'
import { useFarmV3Helper, usePoolV3Contract } from 'hooks/useContract'
import { usePairAddresstoCurrency } from 'hooks/usePairAddresstoCurrency'
import { useAtomValue } from 'jotai/utils'
import JSBI from 'jsbi'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from 'react'
import { CSSProperties, ReactNode } from 'react'
import { Link, useParams } from 'react-router-dom'
import { PriceMap } from 'state/cache/actions'
import { EarningAmounts } from 'state/farm/types'
import styled, { css, useTheme } from 'styled-components'
import { BREAKPOINTS, ClickableStyle } from 'theme'
import { ToDecimalsExpanded } from 'utils/currency'
import { formatUSDPrice } from 'utils/formatNumbers'

import { Incentive, useGetTVL } from '../FarmDetails/details'
import { FarmSortMethod, sortAscendingAtom, sortMethodAtom, useSetSortMethod } from '../state'

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledTokenRow = styled.div<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  display: grid;
  font-size: 16px;
  grid-template-columns: 1fr 7fr 4fr 6fr 6fr 1fr;
  line-height: 24px;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  ${({ first, last }) => css`
    height: ${first || last ? '72px' : '72px'};
    padding-top: ${first ? '8px' : '0px'};
    padding-bottom: ${last ? '8px' : '0px'};
  `}
  padding-left: 12px;
  padding-right: 12px;
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  width: 100%;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.deprecated_hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1fr 6.5fr 4.5fr 6.5fr 6.5fr 1.7fr;
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1fr 6.5fr 4.5fr 6.5fr 6.5fr 1.7fr;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1fr 10fr 5fr 5fr 1.2fr;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 3fr;
    min-width: unset;
    border-bottom: 0.5px solid ${({ theme }) => theme.surface2};

    :last-of-type {
      border-bottom: none;
    }
  }
`

const ClickableContent = styled.div<{ gap?: number }>`
  display: flex;
  ${({ gap }) => gap && `gap: ${gap}px`};
  text-decoration: none;
  color: ${({ theme }) => theme.neutral1};
  font-size: 24x;
  font-weight: 535;
  align-items: center;
  cursor: pointer;
`
const ClickableName = styled(ClickableContent)`
  gap: 8px;
  max-width: 100%;
`
const StyledHeaderRow = styled(StyledTokenRow)`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.neutral1};
  border-radius: 8px 8px 0px 0px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 14px;
  height: 60px;
  line-height: 16px;
  padding: 0px 12px;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

const ListNumberCell = styled(Cell)<{ header: boolean }>`
  color: ${({ theme }) => theme.neutral2};
  min-width: 32px;
  font-size: 14px;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const DataCell = styled(Cell)<{ sortable: boolean }>`
  justify-content: flex-end;
  min-width: 80px;
  user-select: ${({ sortable }) => (sortable ? 'none' : 'unset')};
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
`
const TvlCell = styled(DataCell)`
  font-family: 'Courier New', monospace;
  font-size: 16px;
  padding-right: 8px;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const NameCell = styled(Cell)`
  justify-content: flex-start;
  padding: 0px 8px;
  min-width: 240px;
  gap: 8px;
  font-size: 16px;

  @media only screen and (max-width: ${BREAKPOINTS.xs}px) {
    min-width: 200px;
  }
`
const PriceCell = styled(DataCell)`
  padding-right: 8px;
`
const PercentChangeCell = styled(DataCell)`
  font-size: 16px;
  padding-right: 8px;
  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const PercentChangeInfoCell = styled(Cell)`
  display: none;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: flex;
    gap: 3px;
    justify-content: flex-end;
    color: ${({ theme }) => theme.neutral2};
    font-size: 12px;
    line-height: 16px;
  }
`
const PriceInfoCell = styled(Cell)`
  justify-content: flex-end;
  flex: 1;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    flex-direction: column;
    align-items: flex-end;
  }
`

const HeaderCellWrapper = styled.span<{ onClick?: () => void }>`
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  width: 100%;

  &:hover {
    ${ClickableStyle}
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`
const TokenInfoCell = styled(Cell)`
  gap: 8px;
  line-height: 24px;
  font-size: 16px;
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: flex-start;
    flex-direction: column;
    gap: 0px;
    width: max-content;
    font-weight: 535;
  }
`
const TokenName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`
const TokenSymbol = styled(Cell)`
  color: ${({ theme }) => theme.neutral1};
  text-transform: uppercase;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    font-size: 12px;
    height: 16px;
    justify-content: flex-start;
    width: 100%;
  }
`
const DepositedCell = styled(DataCell)`
  padding-right: 8px;
  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const RewardsCell = styled(DataCell)`
  font-family: 'Courier New', monospace;
  font-size: 16px;
  padding-right: 8px;

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const SmallLoadingBubble = styled(LoadingBubble)`
  width: 25%;
`
const MediumLoadingBubble = styled(LoadingBubble)`
  width: 65%;
`
const IconLoadingBubble = styled(LoadingBubble)`
  border-radius: 50%;
  width: 24px;
`

const InfoIconContainer = styled.div`
  width: 16px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  cursor: help;
`

const HEADER_DESCRIPTIONS: Record<FarmSortMethod, ReactNode | undefined> = {
  [FarmSortMethod.PRICE]: undefined,
  [FarmSortMethod.REWARDS_APY]: (
    <Trans>Annual Percentage Yield (APY) is a representaiton of the compounded yield which is received annually.</Trans>
  ),
  [FarmSortMethod.TOTAL_VALUE_LOCKED]: (
    <Trans>Total value locked (TVL) is the total amount of the asset locked within {SITE_NAME} Farms.</Trans>
  ),
  [FarmSortMethod.DEPOSITED]: (
    <Trans>Deposited is the amount of the asset you have deposited into the {SITE_NAME} v3 Farm.</Trans>
  ),
  [FarmSortMethod.REWARDS]: (
    <Trans>Rewards are the daily emissions of the reward token for the {SITE_NAME} v3 Farm.</Trans>
  ),
}

// Header Cell Component
const HeaderCell: React.FC<{ category: FarmSortMethod }> = ({ category }) => {
  const theme = useTheme()
  const sortAscending = useAtomValue(sortAscendingAtom)
  const handleSortCategory = useSetSortMethod(category)
  const sortMethod = useAtomValue(sortMethodAtom)
  const description = HEADER_DESCRIPTIONS[category]

  return (
    <HeaderCellWrapper onClick={handleSortCategory}>
      {sortMethod === category && (
        <>
          {sortAscending ? (
            <ArrowChangeUp width={16} height={16} color={theme.neutral2} />
          ) : (
            <ArrowChangeDown width={16} height={16} color={theme.neutral2} />
          )}
        </>
      )}
      {category}
      {description && (
        <MouseoverTooltip text={description} placement="right">
          <InfoIconContainer>
            <Info width="16px" height="16px" />
          </InfoIconContainer>
        </MouseoverTooltip>
      )}
    </HeaderCellWrapper>
  )
}

// Farm Row Component (Generalized)
const FarmRow: React.FC<{
  header: boolean
  listNumber: ReactNode
  tokenInfo: ReactNode
  apy: ReactNode
  tvl: ReactNode
  deposited: ReactNode
  claimable: ReactNode
  first?: boolean
  last?: boolean
  style?: CSSProperties
}> = ({ header, listNumber, tokenInfo, apy, tvl, deposited, claimable, ...rest }) => {
  const rowCells = (
    <>
      <ListNumberCell header={header}>{listNumber}</ListNumberCell>
      <NameCell data-testid="name-cell">{tokenInfo}</NameCell>
      <PercentChangeCell data-testid="percent-change-cell" sortable={header}>
        {apy}
      </PercentChangeCell>
      <TvlCell data-testid="tvl-cell" sortable={header}>
        {tvl}
      </TvlCell>
      {/* <DepositedCell data-testid="deposited-cell" sortable={header}>
        {deposited}
      </DepositedCell> */}
      <RewardsCell sortable={header}>{claimable}</RewardsCell>
    </>
  )

  return header ? (
    <StyledHeaderRow data-testid="header-row">{rowCells}</StyledHeaderRow>
  ) : (
    <StyledTokenRow {...rest}>{rowCells}</StyledTokenRow>
  )
}

// Header Row Component (Specific)
export const HeaderRowComponent = () => (
  <FarmRow
    header
    listNumber="#"
    tokenInfo={<Trans>Farm Name</Trans>}
    apy={<HeaderCell category={FarmSortMethod.REWARDS_APY} />}
    tvl={<HeaderCell category={FarmSortMethod.TOTAL_VALUE_LOCKED} />}
    deposited={<HeaderCell category={FarmSortMethod.DEPOSITED} />}
    claimable={<HeaderCell category={FarmSortMethod.REWARDS} />}
  />
)
// Loading Row Component
export const LoadingFarmRow = (props: { first?: boolean; last?: boolean }) => (
  <FarmRow
    header={false}
    listNumber={<SmallLoadingBubble />}
    tokenInfo={
      <>
        <IconLoadingBubble />
        <MediumLoadingBubble />
      </>
    }
    apy={<LoadingBubble />}
    tvl={<LoadingBubble />}
    deposited={<LoadingBubble />}
    claimable={<LoadingBubble />}
    {...props}
  />
)

// --- Helper Functions ---

// Calculate USD value from CurrencyAmount
const toUSD = (priceMap: PriceMap, currencyAmount?: CurrencyAmount<Token>): number => {
  if (!currencyAmount) return 0

  const tokenPrice = priceMap[currencyAmount.currency.address.toLowerCase()] ?? 0
  return Number(currencyAmount.toSignificant()) * tokenPrice
}

// Calculate price amount from LP token
const priceAmountFromLPToken = (
  priceMap: PriceMap,
  pairAmount: CurrencyAmount<Token>,
  tokenAmount?: CurrencyAmount<Token>,
  quoteTokenAmount?: CurrencyAmount<Token>
): number => {
  let usdAmount = toUSD(priceMap, pairAmount)
  if (!usdAmount && tokenAmount && quoteTokenAmount) {
    usdAmount = toUSD(priceMap, tokenAmount) + toUSD(priceMap, quoteTokenAmount)
  }
  return usdAmount
}

function pairAmountsToUSD(
  priceMap: PriceMap,
  currencyAmount0: CurrencyAmount<Token>,
  currencyAmount1: CurrencyAmount<Token>
) {
  const usdAmount0 = toUSD(priceMap, currencyAmount0)
  const usdAmount1 = toUSD(priceMap, currencyAmount1)

  if (
    Number.isNaN(usdAmount0) ||
    Number.isNaN(usdAmount1) ||
    !Number.isFinite(usdAmount0) ||
    !Number.isFinite(usdAmount1)
  ) {
    return 0
  }

  return usdAmount0 + usdAmount1
}
// Calculate Farm APR
const getFarmApr = (
  rewardPerYear: JSBI,
  rewardsPerYearDecimals: number,
  priceUsd: number,
  totalLiquidityUsd: number
): number => {
  const pricePer = Math.round(priceUsd * 1e18)
  const totalLiquidityBI = Math.round(totalLiquidityUsd * 1e18)
  const aprPerShare = new Fraction(pricePer, totalLiquidityBI)
  const apr = aprPerShare.multiply(rewardPerYear).divide(ToDecimalsExpanded(rewardsPerYearDecimals)).multiply(100)
  return Number(apr.toFixed(4))
}

// export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
//   const displayBalance = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals))
//   return displayBalance.toNumber()
// }

interface LoadedRowProps {
  farmListIndex: number
  farmListLength: number
  farm: NonNullable<Incentive>
  sortRank: number
  priceMap: PriceMap
}

// --- Loaded Farm Row Component ---
export const LoadedFarmRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { farmListIndex, farmListLength, farm, sortRank, priceMap } = props
  // State for storing pool data
  const [pool, setPool] = useState<Pool | undefined>()

  const filterNetwork = validateUrlChainParam(useParams<{ chainName?: string }>().chainName?.toUpperCase())
  const chainId = useWeb3React().chainId
  const { pool: poolAddress, endTime, rewardToken } = farm || {}
  // Setup contracts
  const poolV3Contract = usePoolV3Contract(poolAddress)
  const UniV3StakerContract = useFarmV3Helper()

  // Fetch pool details
  const token0Result = useSingleCallResult(poolV3Contract, 'token0', [])
  const token1Result = useSingleCallResult(poolV3Contract, 'token1', [])
  const feeResult = useSingleCallResult(poolV3Contract, 'fee', [])
  const slot0Result = useSingleCallResult(poolV3Contract, 'slot0', [])
  const liquidityResult = useSingleCallResult(poolV3Contract, 'liquidity', [])

  // Fetch reward details
  const rewardResult = useSingleCallResult(UniV3StakerContract, 'incentives', [farm.id])
  const rewardTokensleft = rewardResult?.result?.totalRewardUnclaimed as BigintIsh | undefined
  // Fetch token details
  const rewardTokenQuery = useTokenFromActiveNetwork(rewardToken ?? '0x') as Token
  const token0Address = token0Result?.result?.[0] ?? '0x'
  const token1Address = token1Result?.result?.[0] ?? '0x'
  const [currencyA, currencyB] = usePairAddresstoCurrency(token0Address, token1Address)

  const formattedTokenName = `${currencyA?.symbol} / ${currencyB?.symbol}`

  const tvlAmount = useGetTVL(pool, farm.id)
  const rewardsUSD = rewardTokenQuery
    ? toUSD(priceMap, CurrencyAmount.fromRawAmount(rewardTokenQuery, rewardTokensleft ?? 0))
    : 0

  useEffect(
    () => setupPool(token0Result, token1Result, slot0Result, liquidityResult, chainId ?? 0),
    [token0Result, token1Result, chainId, slot0Result, liquidityResult]
  )
  const setupPool = (token0Result: any, token1Result: any, slot0Result: any, liquidityResult: any, chainId: number) => {
    if (
      !token0Result.pending &&
      !token1Result.pending &&
      !token0Result.loading &&
      !token1Result.loading &&
      !feeResult.loading &&
      token0Result.result &&
      token1Result.result &&
      slot0Result.result &&
      liquidityResult.result &&
      feeResult.result
    ) {
      const tokenA = new Token(chainId, token0Result.result[0], DEFAULT_ERC20_DECIMALS)

      const tokenB = new Token(chainId, token1Result.result[0], DEFAULT_ERC20_DECIMALS)
      const newPool = new Pool(
        tokenA,
        tokenB,
        feeResult.result[0],
        JSBI.BigInt(slot0Result.result.sqrtPriceX96.toString()),
        JSBI.BigInt(liquidityResult.result.toString()),
        slot0Result.result.tick
      ) // Mocked values for sqrtPriceX96, liquidity, tick
      setPool(newPool)
    }
  }

  const remainingDays = useMemo(() => {
    if (!endTime) return undefined

    const now = Math.floor(Date.now() / 1000)
    const remainingSeconds = Number(endTime) - now
    return Math.max(0, Math.ceil(remainingSeconds / (24 * 60 * 60)))
  }, [endTime])

  const tvlFormatted = tvlAmount && tvlAmount > 0.01 ? formatUSDPrice(tvlAmount) : '< $0.01'

  const totalApr = useMemo(() => {
    if (rewardsUSD <= 0 || !tvlAmount) return 0
    return (rewardsUSD / tvlAmount) * (DAYS_IN_YEAR / (remainingDays ?? 1))
  }, [remainingDays, rewardsUSD, tvlAmount])

  const totalAprFormatted =
    totalApr !== undefined
      ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalApr)
      : 'loading...'
  const earnings: EarningAmounts = useMemo(() => {
    if (!rewardTokensleft || !rewardTokenQuery || !remainingDays) {
      return { address: null, earnings: JSBI.BigInt(0) }
    }
    return {
      address: rewardTokenQuery.address,
      earnings: JSBI.divide(JSBI.BigInt(rewardTokensleft), JSBI.BigInt(remainingDays)),
    }
  }, [remainingDays, rewardTokenQuery, rewardTokensleft])

  if (!currencyA || !currencyB) return null

  return (
    <div ref={ref} data-testid={`token-table-row-${farm.pool}`}>
      <StyledLink to={getFarmV3DetailsURL(farm)}>
        <FarmRow
          header={false}
          listNumber={farmListIndex}
          tokenInfo={
            <ClickableName>
              <DoubleCurrencyLogo currency0={currencyB} currency1={currencyA} />
              <TokenInfoCell>
                <TokenSymbol>{formattedTokenName}</TokenSymbol>
              </TokenInfoCell>
            </ClickableName>
          }
          apy={<ClickableContent gap={3}>{totalAprFormatted}%</ClickableContent>}
          tvl={<ClickableContent>{tvlFormatted}</ClickableContent>}
          deposited={<ClickableContent>0</ClickableContent>} // Update if needed
          claimable={earnings && earnings.address ? <Earnings earnings={Array(earnings)} /> : <></>}
          first={farmListIndex === 0}
          last={farmListIndex === farmListLength - 1}
        />
      </StyledLink>
    </div>
  )
})

LoadedFarmRow.displayName = 'LoadedFarmRow'
