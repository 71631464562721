import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { Trans } from '@lingui/macro'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { usePendingActivity } from 'components/AccountDrawer/MiniPortfolio/Activity/hooks'
import Badge from 'components/Badge'
import { ButtonSecondary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RowBetween, RowFixed } from 'components/Row'
import { BIG_INT_ZERO, ZERO_ADDRESS } from 'constants/misc'
import { useToken } from 'hooks/Tokens'
import { useColor } from 'hooks/useColor'
import { useV3PositionsFromFarm } from 'hooks/useV3Positions'
import { useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import { Table } from 'nft/components/explore/Table'
import { CheckMarkIcon } from 'nft/components/icons'
import { themeVars } from 'nft/css/sprinkles.css'
import { useIsMobile } from 'nft/hooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AlertTriangle, CheckCircle } from 'react-feather'
import { Row } from 'react-table'
import styled from 'styled-components'
import { ThemedText } from 'theme'

import { UserPosition } from '../hooks/fetchFarms'
import { PositionMetadataGenerate } from '../hooks/misc'
import { FarmAction, Incentive } from './details'

const WrapperStaked = styled.div`
  display: flex;
  align-items: right;
  justify-content: right;
`

const NFTContainer = styled.div`
  display: flex;
  min-height: 140px;
`

// Define a type for the row data
type RowData = {
  key: string
  nft: string
  tokenId: string
  positionValue: string
  pendingRewards: number
  staked: boolean
  isDeposited: boolean
}

type Props = {
  row: Row<RowData>
}

type PositionType = { isStaked: boolean; hasRewards: boolean; isDeposited: boolean; liquidity: BigNumber }
type PositionMap = { [tokenId: string]: PositionType }

export function PositionsTable({
  positions,
  pool,
  setModal,
  setFarmActionState,
  setToken,
  incentive,
  UniV3StakerContract,
  tokenRewards,
}: {
  positions: UserPosition[]
  pool: Pool
  setModal: (modal: any) => void
  setFarmActionState: (action: FarmAction) => void
  setToken: (token: any) => void
  incentive: Incentive
  UniV3StakerContract: Contract | null
  tokenRewards: { [tokenId: string]: string }
}) {
  const { account } = useWeb3React()
  const [positionStates, setPositionStates] = useState<PositionMap>({})
  // const UniV3StakerContract = useFarmV3Helper()

  const tokenQuote = useToken(pool.token1.address) || undefined
  const tokenBase = useToken(pool.token0.address) || undefined

  const currencyQuoteColor = useColor(tokenQuote)
  const currencyBaseColor = useColor(tokenBase)

  const tokenIds = useMemo(() => {
    return positions.map((position) => position.tokenId.toString())
  }, [positions])
  // Check if positions are staked
  const stakedCalls = useMemo(() => {
    return tokenIds.map((tokenId) => ({
      params: [tokenId],
    }))
  }, [tokenIds])

  const { hasPendingActivity } = usePendingActivity()

  const depositedResults = useSingleContractMultipleData(
    UniV3StakerContract,
    'deposits',
    stakedCalls.map((call) => call.params)
  )

  // Create calls for stakes function
  const stakesCalls = useMemo(() => {
    return tokenIds.map((tokenId) => ({
      params: [tokenId, incentive.id],
    }))
  }, [tokenIds, incentive.id])

  const stakesResults = useSingleContractMultipleData(
    UniV3StakerContract,
    'stakes',
    stakesCalls.map((call) => call.params)
  )

  const { positions: filteredPositions } = useV3PositionsFromFarm(account, pool)

  useEffect(() => {
    const newPositionStates: PositionMap = {}
    depositedResults.forEach((result, index) => {
      if (result.result && result.result.length > 0) {
        const tokenId = tokenIds[index]
        const stakesResult = stakesResults[index]
        const liquidity =
          stakesResult.result && stakesResult.result.length > 0 ? BigNumber.from(stakesResult.result[0]) : 0
        newPositionStates[tokenId] = {
          isStaked: result.result.numberOfStakes > 0,
          hasRewards: false, // We'll update this in the next loop
          isDeposited: result.result.owner !== ZERO_ADDRESS,
          liquidity: BigNumber.from(liquidity),
        }
      }
    })
    setPositionStates(newPositionStates)
  }, [depositedResults, stakesResults, tokenIds])

  // const [rewards, setRewards] = useState<{ [tokenId: string]: string }>({})

  // const poolStruct = [incentive.rewardToken, incentive.pool, incentive.startTime, incentive.endTime, incentive.refundee]
  const positionData = useMemo(() => {
    if (!filteredPositions) {
      return
    }

    return filteredPositions.map((position) => {
      const positionState = positionStates[position.tokenId.toString()]

      return {
        nft: position.tokenId,
        tokenId: position.tokenId.toString(),
        positionValue: '',
        // pendingRewards: rewards[position.tokenId.toString()] || '0',
        staked: positionState?.isStaked ?? false,
        isDeposited: positionState?.isDeposited ?? false,
        liquidity: positionState?.liquidity ?? BIG_INT_ZERO,
      }
    })
  }, [filteredPositions, positionStates])

  const clearPoolData = useCallback(
    (original: any) => {
      setToken(original.tokenId)
      setModal(true)
    },
    [setToken, setModal]
  )

  const handleDeposit = useCallback(
    (original: any): void => {
      setFarmActionState(FarmAction.Deposit)
      clearPoolData(original)
    },
    [setFarmActionState, clearPoolData]
  )

  const handleStake = useCallback(
    (original: any): void => {
      setFarmActionState(FarmAction.Stake)
      clearPoolData(original)
    },
    [setFarmActionState, clearPoolData]
  )

  const handleUnstake = useCallback(
    (original: any): void => {
      setFarmActionState(FarmAction.Unstake)
      clearPoolData(original)
    },
    [setFarmActionState, clearPoolData]
  )

  const handleWithdraw = useCallback(
    (original: any): void => {
      setFarmActionState(FarmAction.Withdraw)
      clearPoolData(original)
    },
    [setFarmActionState, clearPoolData]
  )

  const handleClaim = useCallback(
    (original: any): void => {
      setFarmActionState(FarmAction.Claim)
      clearPoolData(original)
    },
    [setFarmActionState, clearPoolData]
  )

  const isMobile = useIsMobile()

  const columns = React.useMemo(() => {
    return [
      {
        accessor: 'nft',
        title: 'NFT',
        dataIndex: 'nft',
        key: 'nft',
        Cell: ({ row }: Props) => {
          const currentTokenId = row.original.tokenId
          if (!filteredPositions) {
            return <div>Loading position data...</div>
          }
          const positionHere = filteredPositions.find((pos) => pos.tokenId.toString() === currentTokenId)
          if (!positionHere) {
            return <div>Position data unavailable</div>
          }
          return (
            <>
              <NFTContainer>
                {/* <PositionMetadata height={90} position={row.original} /> */}
                <PositionMetadataGenerate
                  key={currentTokenId}
                  pool={pool}
                  currencyQuoteColor={currencyQuoteColor}
                  currencyBaseColor={currencyBaseColor}
                  position={positionHere}
                  height={100}
                />{' '}
              </NFTContainer>
            </>
          )
        },
        Header: () => <div>NFT</div>,
      },
      // {
      //   accessor: 'pendingRewards',
      //   title: 'Pending Rewards',
      //   dataIndex: 'pendingRewards',
      //   key: 'pendingRewards',
      //   Cell: ({ row }: { row: Row }) => (
      //     <RewardCell reward={row.original.pendingRewards} rewardToken={incentive.rewardToken} />
      //   ),
      //   Header: () => <div>Pending Rewards</div>,
      // },
      // {
      //   accessor: 'rewardEstimate',
      //   title: 'Reward Estimate',
      //   Cell: ({ row }: { row: Row<RowData> }) => {
      //     const tokenId = row.original.tokenId.toString()
      //     const state = positionStates[tokenId]
      //     console.log('🚀 ~ columns ~ state:', state)
      //     if (!state) return null // Loading or error state
      //     const rewards = tokenRewards?.[tokenId]?.toString() ?? '0'
      //     return (
      //       <>
      //         {row.original.staked === true && state.liquidity.gt(0) ? (
      //           <RewardCell reward={rewards} rewardToken={incentive.rewardToken} />
      //         ) : (
      //           <></>
      //         )}
      //       </>
      //     )
      //   },
      //   Header: () => <div>Estimated Rewards (After Unstake)</div>,
      // },
      {
        accessor: 'staked',
        title: 'Staked',
        dataIndex: 'staked',
        key: 'staked',
        Cell: ({ row }: { row: Row<RowData> }) => {
          const tokenId = row.original.tokenId
          const state = positionStates[tokenId]
          const rewards = tokenRewards?.[tokenId]?.toString() ?? '0'

          if (!state) return null // Loading or error state
          return (
            <WrapperStaked>
              {row.original.staked === true && state.liquidity.gt(0) ? (
                <>
                  <AutoColumn>
                    <RowFixed alignItems="right" style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <LabelText color={themeVars.colors.success}>
                        <Badge
                          style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'right',
                            textAlign: 'right',
                            width: '100%',
                            background: 'none',
                            padding: '0px',
                          }}
                        >
                          <CheckMarkIcon height="20px" width="20px" color={themeVars.colors.success}></CheckMarkIcon>
                          <BadgeTextSuccess style={{ display: 'flex', gap: '4px' }} color={themeVars.colors.success}>
                            <Trans>Staked</Trans>
                          </BadgeTextSuccess>
                          {/* <CheckCircle color={themeVars.colors.success} width="25px" /> */}
                        </Badge>
                      </LabelText>
                    </RowFixed>

                    <RowFixed>
                      <Badge style={{ gap: '8px', alignItems: 'right', background: 'none' }}>
                        <BadgeText style={{ display: 'flex', gap: '4px' }} color={themeVars.colors.success}>
                          <Trans>Unstake To Claim</Trans>
                        </BadgeText>
                      </Badge>
                      <RewardCell reward={rewards} rewardToken={incentive.rewardToken} />

                      {/* <CheckCircle color={themeVars.colors.success} width="25px" /> */}
                    </RowFixed>
                  </AutoColumn>
                </>
              ) : state.isStaked && state.liquidity.gt(0) ? (
                <>
                  <RowFixed>
                    <LabelText color={themeVars.colors.success}>
                      <Badge style={{ gap: '8px', alignItems: 'right', background: 'none', padding: '0px' }}>
                        <BadgeText color={themeVars.colors.success} style={{ display: 'flex', gap: '4px' }}>
                          <Trans>Staked</Trans>
                          <CheckCircle color={themeVars.colors.success} width="25px" />
                        </BadgeText>
                      </Badge>
                    </LabelText>
                    <RewardCell reward={rewards} rewardToken={incentive.rewardToken} />
                  </RowFixed>
                </>
              ) : state.isStaked && state.liquidity.eq(0) ? (
                <>
                  <RowFixed>
                    <LabelText color="grey">
                      <Badge style={{ gap: '8px', padding: '0px', background: 'none' }}>
                        <BadgeText style={{ color: 'grey', display: 'flex', gap: '4px' }}>
                          <Trans>Staked (Other Farm)</Trans>
                        </BadgeText>
                      </Badge>
                    </LabelText>
                  </RowFixed>
                </>
              ) : state.isDeposited ? (
                <LabelText color="orange">
                  <Badge style={{ gap: '8px', padding: '0px' }}>
                    <BadgeText style={{ color: 'orange', display: 'flex', gap: '4px', background: 'none' }}>
                      <Trans>Deposited - Unstaked</Trans>
                      <AlertTriangle color="orange" width="25px" />
                    </BadgeText>
                  </Badge>
                </LabelText>
              ) : (
                <LabelText color={themeVars.colors.neutral1}>
                  <Badge style={{ gap: '8px', background: 'none', padding: '0px' }}>
                    <BadgeText>
                      <Trans>Not Deposited</Trans>
                    </BadgeText>
                  </Badge>
                </LabelText>
              )}
            </WrapperStaked>
          )
        },
        Header: () => <div>Status</div>,
      },
      {
        accessor: 'manageLiquidity',
        title: 'Manage Liquidity',
        Cell: ({ row }: Props) => {
          const tokenId = row.original.tokenId
          const state = positionStates[tokenId]

          if (!state) return null // Loading or error state

          return (
            <ResponsiveButtonContainer>
              <AutoColumn style={{ width: '100%', rowGap: '0px', alignItems: 'flex-end' }} justify="flex-end">
                {state.isStaked && state.liquidity.gt(0) ? (
                  <ResponsiveButton
                    padding={isMobile ? '6px' : '6px 8px'}
                    onClick={() => handleUnstake(row.original)}
                    disabled={hasPendingActivity}
                  >
                    Unstake
                  </ResponsiveButton>
                ) : state.isStaked && state.liquidity.eq(0) ? (
                  <>
                    <ResponsiveButton
                      padding={isMobile ? '6px' : '6px 8px'}
                      onClick={() => handleStake(row.original)}
                      disabled={hasPendingActivity}
                    >
                      Stake
                    </ResponsiveButton>
                    <ResponsiveButton
                      padding={isMobile ? '6px' : '6px 8px'}
                      onClick={() => handleUnstake(row.original)}
                      disabled={true}
                      style={{ backgroundColor: 'grey' }}
                    >
                      Withdraw
                    </ResponsiveButton>
                  </>
                ) : state.isDeposited ? (
                  <>
                    <ResponsiveButton
                      padding={isMobile ? '6px' : '6px 8px'}
                      onClick={() => handleStake(row.original)}
                      disabled={hasPendingActivity}
                    >
                      Stake
                    </ResponsiveButton>
                    <ResponsiveButton
                      padding={isMobile ? '6px' : '6px 8px'}
                      onClick={() => handleWithdraw(row.original)}
                      disabled={hasPendingActivity}
                    >
                      Withdraw
                    </ResponsiveButton>
                  </>
                ) : (
                  <ResponsiveButton
                    padding={isMobile ? '6px' : '6px 8px'}
                    margin="4px"
                    onClick={() => handleDeposit(row.original)}
                    disabled={hasPendingActivity}
                  >
                    Deposit&Stake
                  </ResponsiveButton>
                )}
                {state.hasRewards && (
                  <ResponsiveButton
                    padding={isMobile ? '6px' : '6px 8px'}
                    onClick={() => handleClaim(row.original)}
                    disabled={hasPendingActivity}
                  >
                    Claim Rewards
                  </ResponsiveButton>
                )}
              </AutoColumn>
            </ResponsiveButtonContainer>
          )
        },
        Header: () => <div>Manage Liquidity</div>,
      },
    ]
  }, [
    filteredPositions,
    pool,
    currencyQuoteColor,
    currencyBaseColor,
    positionStates,
    tokenRewards,
    incentive.rewardToken,
    isMobile,
    hasPendingActivity,
    handleUnstake,
    handleStake,
    handleWithdraw,
    handleDeposit,
    handleClaim,
  ])

  return (
    <Table
      columns={columns}
      data={positionData}
      smallHiddenColumns={['']}
      mediumHiddenColumns={['']}
      largeHiddenColumns={['symbol']}
    />
  )
}
const RewardCell = ({ reward, rewardToken }: { reward: string; rewardToken: string }) => {
  const rewardTokenQuery = useTokenFromActiveNetwork(rewardToken) as Token

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {/* <LightCard padding="6px 8px" maxWidth="60%"> */}
      <AutoColumn gap="md">
        <RowBetween>
          <RowFixed>
            <ThemedText.DeprecatedDarkGray>
              {CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).greaterThan(0) &&
              CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).toFixed(3) === '0.000'
                ? '<0.001'
                : CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).toFixed(
                    CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).greaterThan(1000) ? 2 : 4
                  )}
            </ThemedText.DeprecatedDarkGray>
          </RowFixed>
          {rewardTokenQuery && <CurrencyLogo currency={rewardTokenQuery} size="20px" style={{ marginLeft: '5px' }} />}
        </RowBetween>
      </AutoColumn>
      {/* </LightCard> */}
    </div>
  )
}

const LabelText = styled.div<{ color: string }>`
  align-items: center;
  color: ${({ color }) => color} !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const BadgeText = styled.div`
  font-weight: 535;
  font-size: 14px;
  color: ${({ theme }) => theme.neutral2};
`

const BadgeTextSuccess = styled.div`
  font-weight: 535;
  font-size: 14px;
  color: ${({ theme }) => theme.success};
`

const ResponsiveButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 30px;

  @media (max-width: 768px) {
    justify-content: end;
    width: 100%;
  }
`

const ResponsiveButton = styled(ButtonSecondary)`
  width: 120px;
  margin: 16px;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  background-color: ${({ theme }) => theme.accent1};
  color: white;
  font-weight: 500;
  border: none;
  transition: background-color 0.2s, transform 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.accent2};
    transform: scale(1.03);
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`
